import {isNonEmptyString} from "src/utils";

export class TeamMemberRewardRequest {
  constructor(response) {
    this.id = response.id;
    this.amount = response.amount;
    this.tax_amount = response.tax_amount;
    this.type = response.type;
    this.status = response.status;
    this.code = response.code;
  }
}

export class TeamMemberRewardsStats {
  constructor(stats) {
    this.available_reward_amount = stats.available_reward_amount;
    this.pending_reward_amount = stats.pending_reward_amount;
    this.used_reward_amount = stats.used_reward_amount;
  }

  clone() {
    return new TeamMemberRewardsStats(this);
  }

  decrementAvailableRewardAmount(amount) {
    this.available_reward_amount = this.available_reward_amount - amount;
  }

  total() {
    return (
      this.available_reward_amount + this.pending_reward_amount + this.used_reward_amount
    );
  }
}

export class TeamMemberInfo {
  constructor(stats, isTurkey) {
    this.birth_date = stats.birth_date;
    this.gender = stats.gender;
    this.country = stats.country === "Türkiye" || isTurkey ? "Türkiye" : stats.country;
    this.province = stats.province;
    this.email = stats.email;
  }
}

export class TeamMemberBankDetails {
  constructor(stats) {
    this.bank_full_name = stats.bank_full_name;
    this.bank_name = stats.bank_name;
    this.bank_iban = stats.bank_iban;
    this.bank_identity_number = stats.bank_identity_number;
    this.bank_province = stats.bank_province;
    this.bank_neighborhood = stats.bank_neighborhood;
    this.bank_street = stats.bank_street;
    this.bank_building = stats.bank_building;
    this.bank_postal_code = stats.bank_postal_code;
  }

  isValid() {
    return (
      isNonEmptyString(this.bank_full_name) &&
      isNonEmptyString(this.bank_name) &&
      isNonEmptyString(this.bank_iban) &&
      isNonEmptyString(this.bank_identity_number) &&
      isNonEmptyString(this.bank_province) &&
      isNonEmptyString(this.bank_neighborhood) &&
      isNonEmptyString(this.bank_street) &&
      isNonEmptyString(this.bank_building) &&
      isNonEmptyString(this.bank_postal_code)
    );
  }
}
