export const trTranslations = {
  mySurveys: "Anketlerim",
  myCompletedSurvey: "Tamamlanan Anketlerim",
  totalRewardsReceived: "Alınan Toplam Ödül",
  openSurveys: "Açık Anketler",
  rewards: "Ödüller",
  accountInfo: "Hesap Bilgileri",
  detailedInformation: "Detaylı Bilgi",
  minute: "dk",
  mainPage: {
    howToUse: "Platformumuz nasıl kullanılıyor?",
    inNumbers: "Sayılarla",
    register: "Kayıt Ol",
    fillInSurveys: "Anketleri Doldur",
    winPrize: "Ödül Kazan",
    registerDescr:
      "İlk olarak platformumuza kayıt olun ve içerideki anketlere erişim sağlayın.",
    fillInSurveysDescr: "Platforma giriş yapın ve ilginizi çeken anketi doldurun.",
    winPrizeDescr:
      "Anketleri tamamladıkça çeşitli ödüller kazanın. Ödüller arasında farklı markalardan indirimler, nakit ve daha fazlası yer alabilir.",
    participantPool: "Katılımcı Havuzu",
    surveyDiversity: "Anket Çeşitliliği",
    rewardDiversity: "Ödül Çeşitliliği",
    support: "Destek",
    participantPoolDescr: "Anketlerinize assign edebileceğimiz çok sayıda kişi",
    surveyDiversityDescr: "Çok sayıda taslak anket",
    rewardDiversityDescr:
      "Kazanabileceğiniz hediye çekleri, indirimler, nakit ödüller ve daha fazlası",
    supportDescr: "Anketleriniz için sürekli destek",
    FAQ: "SSS",
  },

  faq: {
    faqTitle: "Sık Sorulan Sorular",
    faqDescr:
      "Aradığınız sorunun cevabını bulamadıysanız bizimle iletişime geçebilirsiniz.",
    accountOperations: "Hesap İşlemleri",
    surveys: "Anketler",
    giftCodes: "Hediye Kodları ",
    howToUseGiftCodes: "Hediye Kodları Nasıl Kullanılır?",
    giftCodeDescr:
      "Hediye kodlarınızı kullanmadan önce lütfen aşağıdaki talimatları dikkatlice okuyunuz.",
    multipleAccount: "Birden Fazla Üyelik Hesabı Açabilir Miyim?",
    changePassword: "Şifremi nasıl değiştirebilirim?",
    notificationPreferences: "Bildirim tercihleri mi nasıl değiştirebilirim?",
    paymentInformation: "Ödeme Alma bilgilerimi nasıl güncellerim?",
    whoCanParticipate: "Anketlere kimler katılabilir?",
    howCanIBeInformed: "Yeni anket ve fırsatlardan nasıl haberdar olabilirim?",
    canIFillMobileDevice: "Mobil Cihazdan Anketleri Doldurabilir Miyim?",
    howCanIUseGiftCodes: "Hediye Kodlarını Nasıl Kullanabilirim?",
    validityPeriod: "Hediye Kodlarının Geçerlilik Süresi Nedir?",
    lostGiftCode: "Hediye Kodumu Kaybettim, Ne Yapmalıyım?",

    answMultipleAccount:
      "Her kullanıcımıza özel tek bir hesap sunuyoruz. Böylece tüm kazançlarınız ve hediye puanlarınız tek hesapta güvenle birikir. Birden fazla hesap açılması durumunda sistem bunu tespit eder ve maalesef birikimlerin korunması mümkün olmaz.",
    answChangePassword:
      "Hesap ayarlarınızdan şifrenizi güncelleyebilirsiniz. Şifrenizi unuttuysanız, giriş sayfasındaki 'Şifremi Unuttum' seçeneğini kullanabilirsiniz.",
    answNotificationPreferences:
      "Hesap ayarlarınızdan e-posta ve diğer bildirim tercihlerinizi özelleştirebilirsiniz. Yeni anketler, ödüller ve özel teklifler hakkında haberdar olmak için bildirimlerinizi açık tutmanızı öneririz.",
    answPaymentInformation:
      "Hesap ayarlarındaki 'Ödeme Bilgileri' bölümünden banka hesap bilgilerinizi güncelleyebilirsiniz. Güvenlik için her güncelleme SMS onayı gerektirir.",
    answWhoCanParticipate:
      "18 yaşını doldurmuş, sisteme kayıtlı tüm kullanıcılarımız anketlere katılabilir. Bazı anketler belirli demografik özelliklere sahip katılımcılar için özelleştirilmiş olabilir.",
    answHowCanIBeInformed:
      "Yeni anketlerden haberdar olmak için: 'Hesap Bilgileri > İletişim Tercihleri' sekmesine girerek bildirimlerini açık tutabilir, e-posta bültenimize kayıt olabilir ve sosyal medya hesaplarımızı (Instagram, Twitter, Facebook) takip edebilirsiniz.",
    answCanIFillMobileDevice:
      "Evet, anketlerimizi mobil cihazlardan (telefon ve tablet) kolayca doldurabilirsiniz.",
    answHowCanIUseGiftCodes:
      "Hediye kodlarınızı anlaşmalı mağazalarda veya online platformlarda kullanabilirsiniz. Detaylı kullanım kılavuzu için 'Hediye Kodları Nasıl Kullanılır' sayfasını ziyaret edebilirsiniz.",
    answValidityPeriod:
      "Hediye kodlarının geçerlilik süresi mağazaya göre değişiklik gösterebilir. Kodunuzun geçerlilik süresini Hesabım > Hediye Kodlarım bölümünden kontrol edebilirsiniz.",
    answLostGiftCode:
      "Hesabım > Hediye Kodlarım bölümünden tüm aktif kodlarınızı görüntüleyebilirsiniz. Sorun yaşamanız durumunda bizimle geçebilirsiniz.",
  },

  rewards: {
    actions: "Eylemler",
    rewards: "Ödüller",
    availablePoints: "Kullanılabilir Puanlar",
    usedPoints: "Kullanılan Puanlar",
    amount: "Miktar: ",
    giftCard: "Hediye Kartı",
    giftCardDescription:
      "Hediye kartlarını (çeklerini) firmalar her alışverişte tek kod şeklinde kullandırmaktadır. Bu sebeple toplu talep etmenizi tavsiye ederiz.",
    giftCardTaxDescription:
      "Hediye kartlarında (çeklerinde) 1 puan 1 TL olarak talep edebilirsiniz. Nakit taleplerinde ise, devlet %10 stopaj vergisi uyguladığı için 1 puan 0.9 TL'ye karşılık gelmektedir.",
    receiveGifts: "Hediye Al",
    sendToIban: "IBAN'a Gönder",
    sendToIbanHelperText: "Nakit gönderimlerde %10 stopaj vergi kesintisi olmaktadır.",
    amountToSend: "Kullanılacak puan",
    totalSendAmount: "Toplam gönderim miktarı:",
    bulkRequestPromotion:
      "Toplu talep et daha çok kazan! 100 ve üzeri taleplerinizde 10 TL ekstra veriyoruz",
    enterBankDetails: "Lütfen Banka Bilgilerinizi Giriniz",
    previousRewardRequests: "Önceki Ödül Talepleri",
    previousRewardRequestsDescription:
      "Ödülünüz en geç 5 iş günü içinde bu ekranda tanımlanacaktır.",
    requestedDate: "Talep Tarihi",
    surveyName: "Anket Adı",
    requestedStatus: "Talep Durumu",
    giftPin: "Hediye Kodu",
    processing: "İnceleniyor",
    approved: "Onaylandı",
    rejected: "Reddedildi",
    cancelled: "İptal Edildi",
    notRequired: "Gerekmiyor",
    pointUsed: "Kullanılan Puan",
    rewardType: "Ödül Türü",
    rewardSentDate: "Ödül Gönderim Tarihi",
    giftCode: "Hediye Kodu",
    noRequests: "Henüz ödül talebi gönderilmedi.",
    pendingPoints: "Bekleyen Puanlar",
    cancelRequest: "Talebi İptal Et",
    rewardCancelConfirmation: "Bu ödül talebini iptal etmek istediğinizden emin misiniz?",
    rewardCancelConfirmationDescription:
      "İptal ettikten sonra bu işlemi geri almanız mümkün olmayacak.",
    cancel: "İptal",
    confirmCancel: "Evet, İptal Et",
    rewardCancelledSuccess: "Ödül talebiniz başarıyla iptal edilmiştir!",
    rewardCancelledError: "Ödül talebinizi iptal ederken bir hata oluştu.",
  },

  completedSurveys: {
    completedSurveys: "Tamamlanan Etkinlikler",
    surveyName: "Anket Adı",
    completedDate: "Tamamlanma Tarihi",
    rewardType: "Ödül Türü",
    surveyStatus: "Anket Durumu",
    rewardStatus: "Ödül Durumu",
    defined: "Tanımlandı",
    undefined: "Tanımlanmadı",
    rewardAccess: "Ödül Erişimi",
    rewardAmount: "Ödül Miktarı",

    ongoingStatus: "Devam Ediyor",
    completedStatus: "Tamamlandı",
  },

  tableHeaders: {
    title: "Başlık",
    questions: "Sorular",
    responses: "Yanıtlar",
    completion: "Tamamlama Oranı",
    created: "Oluşturulma Tarihi",
    updated: "Güncellenme Tarihi",
    actions: "Eylemler",
  },
  surveyFeature: {
    duration: "Süre",
    rewardPoint: "Ödül Puanı: ",
  },
  prev: "Önceki Buton",
  next: "Sonraki Buton",
  startButton: "Başla",
  noDataFound: "Veri bulunamadı",
  settings: {
    surveyTitle: "Anket Başlığı",
    live: "Canlı",
  },
  buttons: {
    downloadDraftExcel: "Taslak Excel Dosyasını İndir",
  },
  importExcelButtonLabel: "Excel Dosyasından İçe Aktar",
  importExcelDialogTitle: "Excel Dosyası İçe Aktar",
  importExcelNameLabel: "İsim",
  importFileButtonLabel: "Dosyayı İçe Aktar",
  createSurveyButtonLabel: "Oluştur",
  createSurveyDialogTitle: "Anket Oluştur",
  nameInputLabel: "Ad",
  createButtonLabel: "Oluştur",
  cancelButtonLabel: "İptal",
  copyLink: "Bağlantıyı Kopyala",
  copySurvey: "Anketi Kopyala",
  delete: "Sil",
  deleteSurveyTitle: "Anketi Sil",
  DeleteSurveyConfirmation:
    "{{surveyTitle}} anketi kalıcı olarak silinecek. Emin misiniz?",

  cancel: "İptal",
  sections: {
    survey: "Anket",
    preview: "Önizleme",
    distributions: "Dağıtımlar",
    results: "Sonuçlar",
    analyticsDashboard: "Analitik Grafik Ekranı",
  },
  basicSettings: {
    title: "Temel Ayarlar",
    language: "Dil",
    languageDescription:
      "Anketinizi yazdığınız dili seçmenizi öneririz. Yanıt veri de bu dilde görüntülenecektir.",
    previousButton: "Önceki Buton",
    previousButtonDescription: "Önceki butonu etkinleştir/devre dışı bırak.",
    ipBlock: "IP Engelleme",
    ipBlockDescription: "Aynı IP'den birden fazla yanıtı engelle.",
  },
  writeSomething: "Bir şeyler yazın",
  unavailableSurveyMessage: "Kullanılamayan Anket Mesajı",
  buttonLabels: {
    update: "Güncelle",
    reset: "Sıfırla",
  },
  blockSettings: {
    editBlock: "Blok Düzenle",
    displayLogicWarning:
      "Aynı blok içinde görüntüleme mantığı ve rastgeleleştirme kullanmaya çalışılıyor. Bu kombinasyon beklenildiği gibi çalışmayabilir.",
    randomizeQuestions: "Soruları Rastgeleleştir",
    addPageBreak: "Tüm Sorulara Sayfa Sonu Ekle",
    makeAllRequired: "Tüm Soruları Zorunlu Yap",
  },
  none: "Seçilmedi",
  male: "Erkek",
  female: "Kadın",
  other: "Diğer",
  all: "Hepsi",

  editInfo: {
    fullName: "Ad Soyad",
    editInfo: "Bilgileri Düzenle",
    mail: "E-posta (info@galataanket.com)",
    phoneNumber: "Telefon No (0532 000 00 00)",
    gender: "Cinsiyet",
    birthday: "Doğum Günü",
    country: "Hangi ülkede yaşıyorsun?",
    country2: "Hangi ülke?",
    province: "Hangi şehir?",
    editAccountInformation: "Hesap Bilgilerini Düzenle",
    saveAccountInformation: "Hesap Bilgilerini Kaydet",
    accountInformation: "Hesap Bilgileri",
    accountInformationDesc:
      "Bu bölümde adınız, e-posta adresiniz ve iletişim bilgileriniz gibi kişisel bilgilerinizi yönetin.",

    changePassword: "Şifre Değiştir",
    changePasswordDesc: "Hesabınızın güvenliğini sağlamak için şifrenizi güncelleyin.",

    cancel: "İptal",
    delete: "Sil",
    changeCommunicationPreferences: "İletişim Tercihlerini Değiştir",
    communicationPreferences: "İletişim Tercihleri",
    communicationPreferencesDesc:
      "E-posta ve SMS ayarları dahil olmak üzere bildirim ve iletişim tercihlerinizi burada ayarlayın,",

    communicationPreferencesDirective:
      "Sizinle nasıl iletişim kuracağımızı kontrol edin. Bildirimleri istediğiniz gibi etkinleştirin veya devre dışı bırakın.",
    receiveEmailNotification: "E-posta yoluyla bildirimler alın.",
    receiveSMSNotification: "SMS yoluyla bildirimler alın.",
    receiveOurUpdates: "En son haberlerimizi ve güncellemelerimizi alın.",

    emailNotifications: "Email Bildirimleri",
    SMSNotifications: "SMS Bildirimleri",
    newsletterSubscription: "Haber Bülteni Aboneliği",

    changesWillBeSaveAutomatically:
      "Tercihlerinizi değiştirdiğinizde değişiklikler otomatik olarak kaydedilecektir.",

    paymentInformation: "Ödeme Bilgileri",
    paymentInformationDesc:
      "Ödeme yöntemlerinizi, fatura bilgilerinizi ve işlem geçmişinizi görüntüleyin ve yönetin",
    deleteAccountConfirmation: "Hesabınızı silmek istediğinizden emin misiniz?",
    deleteAccountConfirmationDescription:
      "Hesabınızın silinmesi geri alınamaz. Tüm verileriniz kalıcı olarak silinecek. Devam etmek istediğinizden emin misiniz?",
    deleteAccount: "Hesabı Sil",
    changePasswordConfirmation: "Şifrenizi değiştirmek istediğinizden emin misiniz?",
    changePasswordConfirmationDescription:
      "Şifrenizi değiştirmek tekrar giriş yapmanızı gerektirecektir. Devam etmek istediğinizden emin misiniz?",
    identityNumber: "Tc Kimlik No",
    address: "Adres",
    addressMin: "Lütfen tam adresinizi yazın.",
  },
  paymentInfo: {
    paymentAlert:
      "Banka hesabına para gönderebilmek için aşağıdaki bilgilerin doldurulması gerekmektedir.",
    bankName: "Banka Adı",
    ibanConfirmation: "Iban Onayı (TRXXXXXXXXXXXXXXXXXXXXXX)",
  },

  editor: {
    endPageLabel: "Son Sayfa",
    createBlockTooltip: "Soru Bloğu Oluştur",
    editBlockTooltip: "Soru Bloğunu Düzenle",
    deleteBlockTooltip: "Soru Bloğunu Sil",
    blockDeletionConfirmation: "bloğu kalıcı olarak silinecek. Emin misin?",
  },
  downloadResults: "Sonuçları İndir",
  resetSurvey: "Anketi Sıfırla",
  questionLabel: "Soru Etiketi",
  duplicate: "Kopyala",
  shuffleQuestion: "Seçenekleri Karıştır",
  deleteQuestion: "Soruyu Sil",
  ignoreRequire: "Zorunlu Cevaplamayı Yoksay",
  ignorePreviewData: "Önizleme Verilerini Yoksay",
  addOption: "Seçenek Ekle",

  label: "Varsayılan Soru Etiketi",
  choice1: "Seçenek 1",
  choice2: "Seçenek 2",
  choice3: "Seçenek 3",
  defaultQuestion: "Varsayılan Soru Başlığı",
  save: "Kaydet",
  reset: "Sıfırla",

  EditQuestion: "Soruyu Düzenle",
  QuestionType: "Soru Tipi",
  Text: "Metin",
  Settings: "Ayarlar",
  Behaviours: "Davranışlar",
  MultipleChoice: "Çoktan Seçmeli",
  ShortText: "Kısa Metin",
  TextGraphic: "Metin / Grafik",
  SurveyQuestion: "Anket Sorusu",
  EmbeddedDataField: "Gömülü Veri Alanı",
  EditQuestionTextDialogTitle: "Soru Metnini Düzenle",
  PlaceholderWriteSomething: "Bir şeyler yazın",
  CancelButton: "İptal",

  SaveButton: "Kaydet",
  RequiredLabel: "Gerekli",
  requiredField: "Bu alanın doldurulması gereklidir.",
  ContentTypeLabel: "İçerik Türü",
  TextLabel: "Metin",
  NumberLabel: "Sayı",
  EmailAddressLabel: "E-posta Adresi",
  RequiredQuestionError: "Bu soru zorunludur.",
  textInputTypeError: "Metin girişi geçersiz.",
  integerInputTypeError: "Sayı girişi geçersiz.",
  emailInputTypeError: "E-posta girişi geçersiz.",
  DisplayLogic: "Görüntüleme Mantığı",
  DownloadQRCode: "QR Kodunu İndir",
  CopyLink: "Bağlantıyı Kopyala",
  AddElement: "Öğe Ekle",
  Link: "Bağlantı",
  QRCode: "QR Kodu",
  CreateQuestion: "Soru Oluştur",
  AddPageBreak: "+ Sayfa Sonu Ekle",
  LogoutButton: "Çıkış Yap",
  AddBlock: "Blok Ekle",
  AddBranch: "Dal Ekle",
  successfullyUpdated: "Başarıyla Güncellendi",

  builder: "Oluşturucu",
  surveyFlow: "Anket akışı",
  surveyOptions: "Anket seçenekleri",
  enabled: "Aktifleştir",

  auth: {
    login: "Giriş yap",
    register: "Kayıt ol",
    registerHelp:
      "Kayıt olmak için sizlere SMS ile link göndereceğiz. Herhangi bir sorun ile karşılaşırsanız <email>anket@galataanket.com</email>. adresine email atabilirsiniz.",
    emailIsRequired: "Lütfen e-posta adresinizi giriniz",
    phoneIsRequired: "Lütfen telefon numaranızı giriniz",
    surnameIsRequired: "Lütfen soyadınızı giriniz",
    nameIsRequired: "Lütfen adınızı giriniz",
    messageIsRequired: "Lütfen mesajınızı giriniz",
    passwordIsRequired: "Lütfen şifrenizi giriniz",
    mustBeValidEmail: "Geçerli bir e-posta adresi giriniz",
    alreadyhaveaccount: "Zaten Hesabın Varsa",
    emailAddressOrPhoneNumber: "Telefon Numarası",
    emailOrPassIncorrect: "Telefon Numarası veya şifre hatalı, lütfen tekrar deneyin.",
    enterValidEmailAddress: "Lütfen geçerli bir telefon numarası giriniz",

    changePassword: {
      oldPassword: "Eski Şifre",
      newPasswordSection: "Yeni Şifre Bölümü",
      oldPasswordRequired: "Eski şifre zorunludur.",
      passwordRequired: "Şifre zorunludur.",
      passwordMinLength: "Şifre en az 8 karakter uzunluğunda olmalıdır.",
      reEnterPasswordRequired: "Lütfen şifrenizi tekrar girin.",
      passwordsNotMatch: "Şifreler uyuşmuyor.",
    },

    editInfo: {
      bank_full_name: "Ad Soyad",
      fullNameRequired: "Ad soyad zorunludur.",
      bank_identity_number: "T.C. Kimlik Numarası",
      identityNumberRequired: "T.C. kimlik numarası zorunludur.",
      invalidIdentityNumber: "Geçersiz T.C. kimlik numarası.",
      bank_address: "Lütfen Adresinizi Tam ve Eksiksiz Olarak Giriniz.",
      addressRequired: "Adres zorunludur.",
      editAccountInformation: "Hesap Bilgilerini Düzenle",
      saveAccountInformation: "Hesap Bilgilerini Kaydet",
    },
    paymentInfo: {
      paymentAlert: "Lütfen aşağıdaki fatura bilgilerini doldurun.",
      bank_name: "Banka Adı",
      bankNameRequired: "Banka adı zorunludur.",
      ibanRequired: "IBAN zorunludur.",
      invalidIban: "Geçersiz IBAN formatı.",
      ibanConfirmation: "IBAN Tekrar",
      ibanConfirmationRequired: "IBAN tekrar zorunludur.",
      ibansNotMatch: "IBAN'lar eşleşmiyor.",
      city: "Şehir",
      neighborhood: "Mahalle",
      street: "Cadde/Sokak",
      building: "Apartman",
      postalCode: "Posta Kodu",
      cityRequired: "Şehir zorunludur.",
      neighborhoodRequired: "Mahalle zorunludur.",
      streetRequired: "Cadde/Sokak zorunludur.",
      buildingRequired: "Apartman zorunludur.",
      postalCodeRequired: "Posta kodu zorunludur.",
      invalidPostalCode: "Geçersiz posta kodu.",
    },

    resetPassword: {
      title: "Şifre Yenile",
      textLabel: "Telefon Numarası",
      buttonLabel: "Aktivasyon linkini gönder",
      invalidInputMessage: "Geçerli bir telefon numarası (05329993377) girilmelidir.",
      confirmationMessage:
        "Eğer bilgileriniz geçerliyse aktivasyon için SMS gönderilecek. \nTelefonunuzu kontrol edebilirsiniz. \nSorularınız için: katilimcihavuzu@galataanket.com",
    },
    emailAddress: "Telefon Numarası",
    phoneNumber: "Telefon Numarası",
    password: "Şifre",
    continue: "Devam et",
    sendnewpassword: "Send a new password",
    sentinfo:
      "Bilgileriniz sistemde yoksa, bir aktivasyon SMS'i alacaksınız. \nTelefonunuzu kontrol edin. \nSorularınız için katilimcihavuzu@galataanket.com adresine başvurabilirsiniz.",
    passwordsentinfo:
      "Bilgileriniz sistemde yoksa, size yeni bir şifre gönderilecektir. Şifrenizi daha sonra hesap ayarlarından değiştirebilirsiniz. Sorularınız için katilimcihavuzu@galataanket.com adresine başvurabilirsiniz.",
    forgotPassword: "Şifrenizi mi unuttunuz?",
    noAccount: "Hesabınız yok mu?",
    reEnterPassword: "Şifre Tekrarı",
    passwordsNotMatch: "Şifreler eşleşmiyor",
    returnToHomepage: "Anasayfaya Dön",
  },
  ComingSoon: "Yakında",
  companyDescription:
    "Galata Anket, dijital anket araçlarını birleştiren bir akademik ve pazar araştırma şirketidir ve müşterilerine veri temelli danışmanlık sunmaktadır.",
  platformTour: "Platform Turu",

  professionalSurveyTeam: "En güvenilir anket deneyimi için profesyonel bir ekip!",
  dedicatedResearchAndAnalysis:
    "Uzman araştırmacılar ve veri analistlerimiz, her müşterinin özel ihtiyaçlarına ve hedeflerine uygun olarak özenle hazırlanan anketleri tasarlamak ve yönetmek için çalışmaktadır. Hedefimiz, online anketler veya odak grupları aracılığıyla doğru, güvenilir ve işlevsel veriler toplamaktır.",
  notOnlyDataCollection: "Bir veriden daha fazlası!",
  clientReportingAndRecommendations:
    "Müşterilerimize ayrıntılı raporlar ve anket sonuçlarının analizini sunmanın yanı sıra, bilgileri kurumlarını geliştirmek ve hedeflerine ulaşmak için nasıl kullanacakları konusunda önerilerde bulunuyoruz.",
  dataDrivenDecisions:
    "Geri Bildirimlerle Büyüme: Müşterilerin Sesiyle Rekabette Öne Çıkın!",
  commitmentToClientSuccess:
    "Müşterilerimizi sağladığımız kaliteli veriler ile rekabetçi piyasada daha görünür kılmak ve kendi müşterilerinin sesini daha net duymalarını sağlamak için çalışıyoruz. Misyonumuza katılarak, siz de müşterilerinizin geri bildirimlerini dinleyebilmenin gücünü keşfedin!",
  messageSent: "Mesajınız gönderildi!",
  messageSendError: "Mesaj gönderilemedi. Lütfen daha sonra tekrar deneyin.",
  welcomeContactPage: "İletişim Sayfamıza Hoş Geldiniz!",
  contactInvitation:
    "Herhangi bir soru veya yorumla bize ulaşmaktan çekinmeyin. Sizden haber almak isteriz!",
  contactUs: "Bize Ulaşın",
  name: "Ad",
  surname: "Soyad",
  mail: "E-posta",
  message: "Mesaj",
  sending: "Gönderiliyor ...",
  sendMessage: "Mesaj Gönder",
  needHelp: "Yardıma mı ihtiyacınız var?",
  home: "Ana Sayfa",
  aboutUs: "Hakkımızda",
  contact: "İletişim",
  platform: "Platform",
  pages: "Sayfalar",
  websiteDisclosure: "Websitesi Aydınlatma Metni",
  cookiePrivacyPolicy: "Çerez ve Gizlilik Politikası",
  dataSubjectApplicationForm: "İlgili Kişi Başvuru Formu",
  ourAddress: "Adresimiz",
  followUs: "Bizi Takip Edin",
  rightsReserved: "Galata Anket. Tüm hakları saklıdır.",
  serverErrorMessage:
    "İstediğiniz içeriğe şu anda ulaşamıyoruz. Lütfen daha sonra tekrar deneyin.",
  generic: {
    genericCard: {
      showMore: "Daha Fazla Göster",
      showLess: "Daha Az Göster",
    },
    genericTablePagination: {
      of: "toplam",
      page: "Sayfa",
      noRecords: "Kayıt bulunamadı",
      previousPage: "Önceki sayfa",
      nextPage: "Sonraki sayfa",
    },
  },
};
