import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {useTranslation} from "react-i18next";

function GenericTablePagination({
  page = 0,
  rowsPerPage = 10,
  count = 0,
  onPageChange = () => {},
}) {
  const {t} = useTranslation();
  const totalPages = Math.ceil(count / rowsPerPage);
  const startIndex = count > 0 ? page * rowsPerPage + 1 : 0;
  const endIndex = Math.min((page + 1) * rowsPerPage, count);

  return (
    <Box display="flex" alignItems="center" justifyContent="right" m={2}>
      <Typography variant="body2" style={{marginRight: "16px"}}>
        {count > 0
          ? `${startIndex}–${endIndex} ${t("generic.genericTablePagination.of")} ${count}`
          : t("generic.genericTablePagination.noRecords")}
      </Typography>

      <FormControl>
        <InputLabel id="page-select-label">
          {t("generic.genericTablePagination.page")}
        </InputLabel>
        <Select
          labelId="page-select-label"
          value={page + 1}
          onChange={e => onPageChange(e.target.value - 1)}
          style={{
            width: "80px",
            marginLeft: "8px",
            marginRight: "8px",
            position: "relative",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Dropdown maksimum yüksekliği
                overflow: "auto",
                boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
              },
            },
          }}
        >
          {Array.from({length: totalPages}, (_, i) => (
            <MenuItem key={i} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <IconButton
        onClick={() => onPageChange(page - 1)}
        disabled={page === 0}
        aria-label={t("generic.genericTablePagination.previousPage")}
      >
        <ChevronLeftIcon />
      </IconButton>

      <Typography variant="body2" style={{margin: "0 8px"}}>
        {totalPages > 0 ? `${page + 1} / ${totalPages}` : `0 / 0`}
      </Typography>

      <IconButton
        onClick={() => onPageChange(page + 1)}
        disabled={page >= totalPages - 1}
        aria-label={t("generic.genericTablePagination.nextPage")}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
}

export default GenericTablePagination;
