export class User {
  constructor(response, is_authenticated, is_initialized) {
    this.email = response.email;
    this.phone = response.phone;
    this.access_token = response.access;
    this.user_token = response.user_token;
    this.reference_id = response.user_token ? response.user_token.split("-")[1] : null;
    this.is_authenticated = is_authenticated;
    this.is_initialized = is_initialized;
  }

  static createDefaultUser() {
    return new User({}, false, false);
  }

  clone() {
    return new User(
      {
        email: this.email,
        phone: this.phone,
        access: this.access_token,
        user_token: this.user_token,
      },
      this.is_authenticated,
      this.is_initialized,
    );
  }
}
