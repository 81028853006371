import axiosInstance from "src/services/axiosInstance";

class SubmissionService {
  async getOrCreateSubmission(payload) {
    return await axiosInstance
      .post("/submissions/initialize", payload)
      .then(submission => {
        return submission?.data;
      })
      .catch(err => {
        console.log("err");
        throw new Error(err);
      });
  }

  async getSubmission(surveyId) {
    return await axiosInstance
      .get(`/projects/${surveyId}/submission`)
      .then(submission => {
        return submission?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async submitPageAnswers(surveyId, data) {
    return await axiosInstance
      .post(`/projects/${surveyId}/submit`, data)
      .then(answer => {
        return answer?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async completeSubmission(surveyId) {
    return await axiosInstance
      .post(`/projects/${surveyId}/complete-submission`)
      .then(answer => {
        return answer?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const submissionService = new SubmissionService();
