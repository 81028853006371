import {useCallback, useEffect, useState} from "react";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Skeleton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {projectService} from "src/services/project/project";
import {ProjectAssignment} from "src/entities/project/project-assignment";
import {paths} from "src/pages/paths";
import {useRouter} from "src/hooks/use-router";
import {Summary} from "src/pages/dashboard/open-surveys/summary";
import {useAuth} from "src/hooks/use-auth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";

export const OpenSurveys = () => {
  const {t, i18n} = useTranslation();
  const [soonProjectAssignments, setSoonProjectAssignments] = useState(null);
  const [activeProjectAssignments, setActiveProjectAssignments] = useState(null);
  const router = useRouter();
  const theme = useTheme();
  const auth = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [copyTooltip, setCopyTooltip] = useState("Kopyalamak için tıkla!");

  useEffect(() => {
    projectService.listProjectAssignments("soon", 1).then(response => {
      const projects = response.results.map(res => new ProjectAssignment(res));
      setSoonProjectAssignments(projects);
    });
    projectService.listProjectAssignments("active", 1).then(response => {
      const projects = response.results.map(res => new ProjectAssignment(res));
      setActiveProjectAssignments(projects);
    });
  }, []);

  const handleStartButton = useCallback(async projectId => {
    if (await auth.isTokenValid()) {
      router.push(paths.survey.replace(":id", projectId));
    } else {
      router.push(paths.auth.login);
    }
  }, []);

  const handleCopyReferenceId = useCallback(() => {
    if (auth.user?.reference_id) {
      const referenceId = auth.user?.reference_id;
      navigator.clipboard.writeText(
        `${window.location.origin}${paths.auth.register}?reference_id=${referenceId}`,
      );
      setCopyTooltip("Kopyalandı!");
      setTimeout(() => {
        setCopyTooltip("Kopyalamak için tıkla!");
      }, 2000);
    }
  }, [auth.user]);

  const LoadingSkeleton = () => (
    <>
      {[1, 2].map(item => (
        <Card key={item} sx={{width: isMobile ? "100%" : "24%", minHeight: "150px"}}>
          <CardContent>
            <Skeleton variant="text" width="70%" height={30} />
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="rounded" width={100} sx={{mt: 1}} height={40} />
          </CardContent>
        </Card>
      ))}
    </>
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{py: 4}}>
        {/* Reference ID Copy Button */}
        <Box display="flex" justifyContent="center" sx={{mb: 2}}>
          <Tooltip title={copyTooltip}>
            <Button
              startIcon={<ContentCopyIcon />}
              onClick={handleCopyReferenceId}
              size="small"
            >
              Arkadaşını katılımcı havuzuna davet et, hem sen hem o, 10 puan kazan.
            </Button>
          </Tooltip>
        </Box>

        {/* Summary Boxes */}
        <Summary />

        {/* Open Surveys Section */}
        <Typography variant="h5" sx={{mb: 2}}>
          {t("openSurveys")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "left",
            gap: 2,
            mb: 4,
          }}
        >
          {/* Survey Cards */}
          {soonProjectAssignments === null || activeProjectAssignments === null ? (
            <LoadingSkeleton />
          ) : (
            <>
              {soonProjectAssignments.map(projectAssignment => (
                <Card sx={{width: isMobile ? "100%" : "24%", minHeight: "150px"}}>
                  <CardContent>
                    <Typography variant="h6">{projectAssignment.topic}</Typography>
                    <Typography variant="body2">
                      {`${t("surveyFeature.duration")}: ${projectAssignment.duration} ${t("minute")}`}
                    </Typography>
                    <Typography variant="body2">
                      {t("surveyFeature.rewardPoint")} {projectAssignment.reward}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        mt: 2,
                      }}
                      disabled={true}
                    >
                      {t("ComingSoon")}
                    </Button>
                  </CardContent>
                </Card>
              ))}
              {activeProjectAssignments.map(projectAssignment => (
                <Card sx={{width: isMobile ? "100%" : "24%", minHeight: "150px"}}>
                  <CardContent>
                    <Typography variant="h6">{projectAssignment.topic}</Typography>
                    <Typography variant="body2">
                      {`${t("surveyFeature.duration")}: ${projectAssignment.duration} ${t("minute")}`}
                    </Typography>
                    <Typography variant="body2">
                      {t("surveyFeature.rewardPoint")} {projectAssignment.reward}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        mt: 2,
                      }}
                      onClick={_ => handleStartButton(projectAssignment.projectId)}
                    >
                      {t("startButton")}
                    </Button>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};
