import {submissionStatus} from "src/schemas/submission/submission-status";
import {
  SurveyEngineConsumer,
  SurveyEngineProvider,
} from "src/contexts/survey-engine-context";
import {DisabledSurveyPage} from "src/pages/survey/disabled-survey-page";
import {Survey} from "src/pages/survey/index";
import surveyI18n from "src/locales/survey-translations/i18n";
import {I18nextProvider, useTranslation} from "react-i18next";
import {GifSplashScreen} from "src/components/gif-splash-screen";
import React from "react";

export default function SurveyEngine() {
  const {t} = useTranslation();
  return (
    <SurveyEngineProvider submissionStatus={submissionStatus.ONLINE}>
      <SurveyEngineConsumer>
        {surveyEngine => {
          if (surveyEngine.survey) {
            if (surveyEngine.survey.is_active) {
              surveyI18n.changeLanguage(surveyEngine.survey.language);
              return (
                <I18nextProvider i18n={surveyI18n}>
                  <Survey />
                </I18nextProvider>
              );
            } else {
              return (
                <DisabledSurveyPage
                  message={surveyEngine.survey.inactive_survey_message}
                />
              );
            }
          } else if (surveyEngine.error) {
            return <DisabledSurveyPage message={t("serverErrorMessage")} />;
          } else {
            return <GifSplashScreen />;
          }
        }}
      </SurveyEngineConsumer>
    </SurveyEngineProvider>
  );
}
