import {createContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {paths} from "src/pages/paths";
import {ACCESS_TOKEN_KEY} from "src/services/axiosInstance";
import {authService} from "src/services/auth";
import {User} from "src/entities/user";

export const AuthContext = createContext({
  user: null,
});

export const AuthProvider = props => {
  const {children} = props;
  const navigate = useNavigate();
  const [user, setUser] = useState(User.createDefaultUser());

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (token) {
      authService.me().then(response => {
        if (response) {
          setUser(new User(response, true, true));
        } else {
          user.is_initialized = true;
          setUser(user.clone());
          signOut();
        }
      });
    } else {
      user.is_initialized = true;
      setUser(user.clone());
    }
  }, []);

  const isLoggedIn = _ => {
    if (user.is_authenticated) {
      return true;
    }
    return false;
  };

  const isTokenValid = async _ => {
    return await authService.me();
  };

  const signIn = async (emailOrPhone, password) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^05\d{9}$/;

    const data = {password: password};
    if (emailRegex.test(emailOrPhone)) {
      data["email"] = emailOrPhone;
    } else if (phoneRegex.test(emailOrPhone)) {
      data["phone"] = emailOrPhone;
    }

    return await authService
      .signIn(data)
      .then(response => {
        setUser(new User(response, true, true));
      })
      .catch(err => {
        throw err;
      });
  };

  const register = async (emailOrPhone, uniqueParameters) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^05\d{9}$/;

    const data = {
      source: uniqueParameters.source,
      invited_by: uniqueParameters.reference_id,
    };
    if (emailRegex.test(emailOrPhone)) {
      data["email"] = emailOrPhone;
    } else if (phoneRegex.test(emailOrPhone)) {
      data["phone"] = emailOrPhone;
    }

    return await authService.register(data);
  };

  const signOut = async () => {
    return await authService.signOut().then(_ => {
      setUser(User.createDefaultUser());
      navigate(paths.auth.login);
    });
  };

  const resetPassword = async emailOrPhone => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^05\d{9}$/;

    const data = {};
    if (emailRegex.test(emailOrPhone)) {
      data["email"] = emailOrPhone;
    } else if (phoneRegex.test(emailOrPhone)) {
      data["phone"] = emailOrPhone;
    }
    return await authService.resetPassword(data);
  };

  return (
    <AuthContext.Provider
      value={{
        user,

        signIn,
        register,
        signOut,
        resetPassword,
        isLoggedIn,
        isTokenValid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
